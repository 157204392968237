(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/oddset-kambi/components/client-events-handler/assets/javascripts/constants.js') >= 0) return;  svs.modules.push('/modules/oddset-kambi/components/client-events-handler/assets/javascripts/constants.js');
"use strict";


const NotificationEvent = {
  BET_SELECTION_READY: 'betSelectionReady',
  BET_VALIDATION_COMPLETED: 'betValidationCompleted',
  PAGE_META_DATA: 'pageMetaData',
  DATA_LAYER_PUSHED: 'dataLayerPushed',
  ROUTE_CHANGE: 'routeChange',
  MAINTENANCE: 'maintenance',
  PLACING_BET_END: 'placingBetEnd',
  PLACING_BET_FAILED: 'placingBetFailed',
  CUSTOMIZED_FEEDBACK: 'customizedFeedback'
};
const DataLayerEvent = {
  ADD_TO_BETSLIP: 'add_to_cart',
  REMOVE_FROM_BETSLIP: 'remove_from_cart',
  CLEAR_BETSLIP: 'kambi clear betslip',
  PAYMENT_COMPLETE: 'purchase',
  RECIEPT_CLOSED: 'kambi keep coupon selection',
  SANDWICH_FILTER_CLICK: 'kambi sandwich filter click',
  CASHOUT: 'refund'
};
setGlobal('svs.oddset_kambi.components.clientEventsHandler.constants', {
  NotificationEvent,
  DataLayerEvent
});

 })(window);