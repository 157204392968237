(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/oddset-kambi/components/client-events-handler/assets/javascripts/notifications-handler.js') >= 0) return;  svs.modules.push('/modules/oddset-kambi/components/client-events-handler/assets/javascripts/notifications-handler.js');
"use strict";

var _svs$core$config$data, _svs$core$config$data2;
const {
  subMinutes
} = dateFns;
const isSelfService = (_svs$core$config$data = (_svs$core$config$data2 = svs.core.config.data.svsconfig) === null || _svs$core$config$data2 === void 0 ? void 0 : _svs$core$config$data2.isSelfService) !== null && _svs$core$config$data !== void 0 ? _svs$core$config$data : false;
const {
  addToCart,
  parseWager
} = svs.components.oddset.selfserviceIntegration;
const {
  utils: {
    updateBalance
  }
} = svs.oddset_kambi.components.common;
const {
  NotificationEvent,
  DataLayerEvent
} = svs.oddset_kambi.components.clientEventsHandler.constants;
const {
  betSlipEvents: {
    trackAddOutcomeToBetslip,
    trackClearBetslip,
    trackBetReceiptKeepCouponSelection
  },
  transactionEvents: {
    trackPayment,
    trackCashout
  },
  miscEvents: {
    trackSandwichFilterClicked
  }
} = svs.oddset_kambi.components.analytics;
const {
  handleCustomizedFeedback
} = svs.oddset_kambi.components.customizedFeedbackHandler;
const {
  clearBetslip,
  hideSportsbook,
  showSportsbook
} = svs.oddset_kambi.components.wapiIntegration;
const logger = svs.core.log.getLogger('oddset_kambi:components:clientEventsHandle:notificationsHandler');
const showGrasrotenDialog = svs.components.oddset.common.grasroten;

const getDateWithOffset = dateString => {
  const date = new Date(dateString);
  const localOffset = new Date().getTimezoneOffset();
  return subMinutes(date, localOffset);
};

const handleDataLayerEvent = (eventData, _ref) => {
  var _eventData$ecommerce;
  let {
    refreshPendingBets
  } = _ref;
  switch (eventData.event) {
    case DataLayerEvent.ADD_TO_BETSLIP:
      trackAddOutcomeToBetslip(eventData);
      break;
    case DataLayerEvent.CLEAR_BETSLIP:
      trackClearBetslip(eventData);
      break;
    case DataLayerEvent.PAYMENT_COMPLETE:
      trackPayment(eventData);
      updateBalance();
      refreshPendingBets();
      break;
    case DataLayerEvent.RECIEPT_CLOSED:
      if (!isSelfService) {
        trackBetReceiptKeepCouponSelection(eventData);
        showGrasrotenDialog();
      }
      break;
    case DataLayerEvent.SANDWICH_FILTER_CLICK:
      trackSandwichFilterClicked(eventData);
      break;
    case DataLayerEvent.CASHOUT:
      if ((_eventData$ecommerce = eventData.ecommerce) !== null && _eventData$ecommerce !== void 0 && _eventData$ecommerce.items) {
        trackCashout(eventData);
        updateBalance();
      }
      refreshPendingBets();
      break;
    default:
      break;
  }
};

const getNotificationsHandler = _ref2 => {
  let {
    setKambiMaintenance,
    setActivePathname,
    refreshPendingBets,
    refreshPendingBetsThrottled
  } = _ref2;
  return _ref3 => {
    let {
      name,
      data,
      state
    } = _ref3;
    switch (name) {
      case NotificationEvent.BET_SELECTION_READY:
        {
          if (isSelfService) {
            addToCart(parseWager(data));
            clearBetslip();
          }
          break;
        }
      case NotificationEvent.DATA_LAYER_PUSHED:
        handleDataLayerEvent(data, {
          refreshPendingBets
        });
        break;
      case NotificationEvent.MAINTENANCE:
        {
          if (state.isMaintenance) {
            const startDate = getDateWithOffset(state.startingAt);
            const endDate = getDateWithOffset(state.endingAt);
            setKambiMaintenance({
              isMaintenance: state.isMaintenance,
              endingAt: endDate,
              startingAt: startDate
            });
            hideSportsbook();
            logger.info('The sportsbetting system is currently being upgraded, will be back at ', endDate);
          } else {
            setKambiMaintenance(null);
            showSportsbook();
          }
          break;
        }
      case NotificationEvent.CUSTOMIZED_FEEDBACK:
        {
          handleCustomizedFeedback(data);
          break;
        }
      case NotificationEvent.ROUTE_CHANGE:
        {
          refreshPendingBetsThrottled();
          if (data.currentRoute === 'home') {
            setActivePathname('/');
          } else {
            setActivePathname("/".concat(data.currentRoute));
          }
          break;
        }
      default:
        break;
    }
  };
};
setGlobal('svs.oddset_kambi.components.clientEventsHandler.getNotificationsHandler', getNotificationsHandler);

 })(window);